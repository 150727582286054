.App {
	height: 100%;
	width: 100%;
	min-width: 1000px;
	/* min-height: 100vh; */
	background-color: #ffffff;
}

.ant-modal-wrap.cust-modal-class .ant-modal .ant-modal-content {
	box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.05) !important;
	border-radius: 10px !important;
}

.ant-table-tbody > tr > td {
	border-bottom: 1px solid #e7e7e7;
}
.ant-table-thead > tr > th {
	padding: 0.8rem 0.5rem;
}
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
	padding: 0.3rem 0.4rem;
}
.ant-table-cell,
.ant-table-row {
	min-width: 170px;
}
.ant-table-body table {
	table-layout: unset !important;
}
.flexed {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
}
/* .ant-table-header table{
	table-layout: unset !important;
} */
.export {
	cursor: pointer;
	background-color: blue;
	color: white;
	border-radius: 4px;
	padding: 0px 25px;
	font-weight: 500;
	display: flex;
	justify-self: center;
	align-items: center;
	height: 34px;
}
.search-input {
	width: 300px !important;
	height: 34px;
	border-radius: 4px;
	margin-right: 1rem;
}
.ant-select,
.select {
	height: 38px;
}
.search-input .ant-input-group,
.search-input .ant-input,
.search-input .ant-btn,
.search-input .ant-input-affix-wrapper {
	height: 100%;
}

.card-item {
	display: flex;
	width: 100%;
	border-radius: 6px;
	background-color: white;
	min-height: 5rem;
	align-items: center;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
	padding: 0.5rem 1rem;
}
.flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
.main-text {
	font-size: 1.5rem;
	font-weight: 600;
	margin-bottom: 0;
}
.sub-text {
	font-size: 0.81rem;
	font-weight: 400;
	color: #8c8c8c;
	margin-bottom: 0;
}
.icon-box {
	border-radius: 50%;
	width: 2rem;
	height: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
}
.icon-type {
	font-size: 0.8rem;
	fill: inherit;
}
.grid {
	display: grid;
	width: 100%;
}
.grid-4 {
	grid-template-columns: repeat(4, 1fr);
	gap: 1rem;
}

.group-item {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.title {
	font-weight: 600;
	font-size: 0.8rem;
}
.sub {
	font-weight: 400;
	font-size: 1rem;
}
.name-box {
	border-radius: 6px;
	padding: 1rem;
	display: flex;
	flex-direction: row;
	gap: 1rem;
	border: 1px solid #c4c5c6;
	width: 100%;
	justify-content: space-between;
}
.main-div {
	width: 100%;
	display: grid;
	grid-template-columns: 3fr 1fr;
	margin-bottom: 2rem;
	gap: 2rem;
}
.plan-type {
	width: fit-content;
	border-radius: 4px;
	padding: 0.3rem 1rem;
	color: white;
}
.long {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}
.short {
	border-radius: 6px;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	border: 1px solid #c4c4c4;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.02);
	width: 100%;
}
.thresh-btn,
.credit-btn {
	background-color: #273df2 !important;
	color: white !important;
	font-size: 0.9rem !important;
	border-radius: 6px !important;
	height: 2.5rem !important;
}
.credit-btn {
	height: 3rem !important;
}
.ant-btn[disabled] {
	background-color: #e7e7e7 !important;
	color: #8c8c8c !important;
}
.thresh-input {
	font-size: 0.9rem;
	border-radius: 4px;
	width: 100%;
	padding: 0.5rem 1rem;
	outline: 0;
	border: 1px solid #8c8c8c;
}
.thresh-label {
	font-size: 0.8rem;
	font-weight: 700;
}
.disabled-row {
	background-color: #ffc0cb;
}
.credit-input {
	font-size: 0.9rem;
	border-radius: 25px;
	width: 100%;
	padding: 0.5rem 1rem;
	outline: 0;
	border: 1px solid #8c8c8c;
	height: 2.8rem;
}
.form-div {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 50%;
}
.with-btn {
	position: relative;
}
.with-btn button {
	position: absolute !important;
	right: 1%;
	top: 40%;
	border: 0;
	background-color: #273df2 !important;
	color: white;
	font-size: 0.8rem;
	border-radius: 25px;
	height: 2.2rem;
	padding: 0 1rem;
}
.with-btn button:hover {
	background-color: #2f3c9f;
	color: white;
}
